/* eslint-disable camelcase */
import { useCallback } from "react"
import useTracking from "./useTracking"
import { v4 as uuidv4 } from "uuid"

import {
  ClientTrackingEventName,
  ClientTrackingOnlineStatus,
  IAdvisorProduct,
} from "../../types"
import { PaymentMethodBrand } from "../../ui-lib/Payment/types"
import { ICheckoutProduct } from "../../ui-lib/ProductFlow/types"
import { getExpertTrackingProperties, getProductVariant } from "../../ui-lib/Experts/utils"
import { useRuntimeConfig } from "../runtimeConfig"
import { getCheckoutId, setCheckoutId } from "./utils"

interface ITrackCouponList {
  position: string
  coupon_id: string
  coupon_title: string
  coupon_type: string
}

interface ITrackProps {
  eventName: ClientTrackingEventName
  callback?: () => void
  billing_option?: number
  payment_method?: "balance" | PaymentMethodBrand | null
  status?: ClientTrackingOnlineStatus
  coupon_list?: ITrackCouponList[] | null
  active_coupon_id?: string | null | "not_selected"
  active_coupon_title?: string | null | "not_selected"
  active_coupon_type?: string | null | "not_selected"
}

/**
 * Currently checkoutId has issue when working with checkout from
 * multiple tabs in one browser. Please check ticket and comments:
 * https://adviqo.atlassian.net/browse/FEM-271?focusedCommentId=167559
 *
 * For now we left implementation as it is until we have issues with
 * checkoutId and tracking.
 */
const useCheckoutTracking = () => {
  const { trackEvent } = useTracking()
  const { getRuntimeConfig } = useRuntimeConfig()

  const { SERVICE_FEE_VALUE = 0, MANDATOR_NUMBER } = getRuntimeConfig()

  const trackCheckoutEvent = useCallback(
    (props: ITrackProps) => {
      const { eventName, callback, ...rest } = props

      const persistedCheckoutId = getCheckoutId()

      const checkoutId = persistedCheckoutId || uuidv4()

      if (!persistedCheckoutId) {
        setCheckoutId(checkoutId)
      }
      trackEvent({
        eventName,
        properties: {
          ...rest,
          ...(eventName === ClientTrackingEventName.PRODUCT_ADD
            ? { cart_id: checkoutId }
            : { checkout_id: checkoutId }),
        },
        callback() {
          if (typeof callback === "function") callback()
        },
      })
    },
    [trackEvent]
  )

  const getCheckoutProductTrackingProperties = (
    listingNo: string,
    displayName: string,
    product: IAdvisorProduct | ICheckoutProduct,
    isReservation: boolean
  ) => {  
    return {
      products: [
        {
          ...getExpertTrackingProperties(listingNo, displayName, MANDATOR_NUMBER),
          variant: getProductVariant(product, isReservation),
          price: product.price,
        },
      ],
      currency: "EUR",
      service_fee: SERVICE_FEE_VALUE,
      value: Math.round((SERVICE_FEE_VALUE + product.price) * 100) / 100,
    }
  }

  return { trackCheckoutEvent, getCheckoutProductTrackingProperties }
}

export default useCheckoutTracking
