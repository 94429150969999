import { IInitTraits } from "../../types"
import cookies from "../cookies"
import { isQuesticoBrand } from "../utils"
import { ReadonlyURLSearchParams } from "next/navigation"

type IStorageTracking = Record<string, string | null>

export function getInitTraits(query: ReadonlyURLSearchParams): IInitTraits {
  const brandName = isQuesticoBrand() ? "Questico" : "Viversum"

  const traits: IInitTraits = {
    brand: brandName,
    current_locale: window.navigator.language,
    google_analytics_ga: undefined,
    current_campaign: {
      referer: query.get("referer") || null,
      pid: query.get("pid") || null,
    },
    current_tracking_id: {
      gclid: query.get("gclid") || null,
      fbclid: query.get("fbclid") || null,
      transaction_id: query.get("transaction_id") || null,
    },
  }

  try {
    traits.google_analytics_ga = cookies.get(window.document.cookie, "_ga")
  } catch (e) {
    traits.google_analytics_ga = undefined
  }

  return traits
}

export function getStorageTrackingItems(
  trackingKeys: string[],
  getter: (k: string) => any
): IStorageTracking | null {
  if (!trackingKeys.length) return null

  return trackingKeys.reduce((acc: IStorageTracking, k) => {
    acc[k] = getter(k)
    return acc
  }, {})
}
