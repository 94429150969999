import React, { useEffect, useState, useRef, ReactNode } from "react"

import { isServer } from "../../utils/utils"
import ConsentContext, { IConsentState, IIntegrations } from "./ConsentContext"

enum UsercentricsEvent {
  INITIALIZED = "UC_UI_INITIALIZED",
  CONSENT = "ucEvent",
}

interface IUCEventDetail {
  event: "consent_status"
  type: "explicit" | "implicit"
  action: "onUpdateServices" | "onInitialPageLoad"
  ucCategory: Record<string, boolean>
  [key: string]: any
}

function integrationsChanged(
  prevIntegrations: IIntegrations | undefined,
  newIntegrations: IIntegrations
) {
  if (prevIntegrations === undefined) return true

  return Object.keys(newIntegrations).some(
    (key) => newIntegrations[key] !== prevIntegrations[key]
  )
}

const ConsentProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const integrationsRef = useRef<IIntegrations>()
  const [consentState, setConsentState] = useState<IConsentState>({
    usercentricsIsReady: false,
    consentIsRequired: isServer() ? false : null,
    clientTrackingConsentIsGiven: false,
    integrations: undefined,
  })

  const onConsent = (e: CustomEvent<IUCEventDetail>) => {
    const { event, type, ...integrations } = e.detail

    if (event === "consent_status") {
      // For EU countries user gives explicit consent and then we need to load client tracking library
      if (type === "explicit") {
        if (!integrationsRef.current) {
          integrationsRef.current = integrations
          setConsentState((prevState) => ({
            ...prevState,
            clientTrackingConsentIsGiven: true,
            integrations,
          }))
        } else if (integrationsChanged(integrationsRef.current, integrations)) {
          window.location.reload()
        }
      }
    }
  }

  // subscribe for usercentrics init
  // @note window event for usercentrics consent is "ucEvent"
  useEffect(() => {
    /**
     * we don't need to show ucersentrics interface
     * for 3rd-party domains
     * @see https://usercentrics.atlassian.net/servicedesk/customer/portal/2/article/953548878?src=-1780869649
     */
    if (window !== window.parent) {
      window.UC_UI_SUPPRESS_CMP_DISPLAY = true
    }

    const onUsercentricsUIInit = () => {
      setConsentState((prevState) => ({
        ...prevState,
        consentIsRequired: window.UC_UI?.isConsentRequired(),
        usercentricsIsReady: true,
      }))

      // eslint-disable-next-line no-underscore-dangle
      if (!window.__tcfapi) {
        return
      }

      // eslint-disable-next-line no-underscore-dangle
      window.__tcfapi(
        "addEventListener",
        2,
        (tcData: { gdprApplies: boolean }, success: boolean) => {
          // For non-EU countries tcData.gdprApplies should have value `false` and then we need to load client tracking library
          // We use TCF v2 https://github.com/InteractiveAdvertisingBureau/GDPR-Transparency-and-Consent-Framework/blob/master/TCFv2/IAB%20Tech%20Lab%20-%20CMP%20API%20v2.md#gettcdata
          if (success && tcData.gdprApplies === false) {
            setConsentState((prevState) => ({
              ...prevState,
              clientTrackingConsentIsGiven: true,
            }))
          }
        }
      )
    }

    if (window.UC_UI && window.UC_UI.isInitialized()) {
      onUsercentricsUIInit()
    } else {
      window.addEventListener(
        UsercentricsEvent.INITIALIZED,
        onUsercentricsUIInit
      )
    }

    // @ts-ignore
    window.addEventListener(UsercentricsEvent.CONSENT, onConsent)

    return function clean() {
      window.removeEventListener(
        UsercentricsEvent.INITIALIZED,
        onUsercentricsUIInit
      )
      // @ts-ignore
      window.removeEventListener(UsercentricsEvent.CONSENT, onConsent)
    }
  }, [])

  return (
    <ConsentContext.Provider value={consentState}>
      {children}
    </ConsentContext.Provider>
  )
}

export default ConsentProvider
