import { IncomingMessage } from "http"

import { ILogLevel } from "../../types"
import { getRuntimeConfig } from "../runtimeConfig"

const { LOGS_LEVEL } = getRuntimeConfig()

type ILogSeverity = 0 | 1 | 2 | 3 | 4

interface ILogOptions {
  message?: string
  payload?: Record<string, any> | unknown
  logHealthCheckRequest?: boolean
}

const LOG_LEVEL_TO_SEVERITY_MAP: Record<ILogLevel, ILogSeverity> = {
  debug: 0,
  log: 1,
  info: 2,
  warn: 3,
  error: 4,
}

const MIN_SEVERITY = LOG_LEVEL_TO_SEVERITY_MAP[LOGS_LEVEL]

const getSeverityByLogLevel = (level: ILogLevel): ILogSeverity => LOG_LEVEL_TO_SEVERITY_MAP[level]

// sample Pingdom.com_bot_version_1.4_(http://www.pingdom.com/)
export const isHealthCheckRequest = (
  request: IncomingMessage | undefined
): boolean =>
  Boolean(
    request?.url === "/ping" ||
      request?.headers["user-agent"]?.toLowerCase().includes("pingdom")
  )

export function log(
  request: IncomingMessage | undefined,
  level: ILogLevel,
  options?: ILogOptions
): void {
  const { message, payload, logHealthCheckRequest = false } =
    options || {}

  const currentSeverity = getSeverityByLogLevel(level)

  const healthCheckRequest = isHealthCheckRequest(request)

  if (healthCheckRequest && !logHealthCheckRequest) {
    return
  }

  if (currentSeverity < MIN_SEVERITY) return

  try {
    const req = request
      ? {
          method: request.method,
          ...request.headers,
          href: `${request.headers.host}${request.url}`,
          "proxy-server-ip": request.socket.remoteAddress || "",
        }
      : undefined

    const m =
      level === "error"
        ? // @ts-expect-error payload could be an error with a message
          `${message}${payload?.message ? ". " + payload.message : ""}`
        : message

    // eslint-disable-next-line
    console.log(
      JSON.stringify({
        severity: level.toUpperCase(),
        message: healthCheckRequest ? "Healthcheck request" : m,
        payload,
        request: req,
      })
    )
  } catch (e) {
    console.error(e)
  }
}
