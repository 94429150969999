import {
  BrandsEnum,
  IAdvisor,
  IAdvisorProduct,
  IAdvisorStatus,
  IAdvisorProfile,
  ICategory,
  MouseEventButton,
  ClientTrackingEventName,
  ClientTrackingOnlineStatus,
  IPromotionAdvisor,
  IAdvisorGender,
} from "../../types"
import { buildProfileUrl } from "../../utils/slugUtils"
import { IDispatchTrackingData } from "../../hooks/tracking"
import { TMouseClick } from "../../hooks/tracking/types"
import { getCheapestProduct } from "./Products/utils"
import { ICheckoutProduct } from "../ProductFlow/types"

import {
  ICommonClientTrackingProductProperties,
  IClientTrackingProduct,
  IClientTrackingProductOptions,
  TClientTrackingProducts,
} from "./ExpertsService/hooks/types"
import { IExpertsIndexesInView } from "./types"
import { getBrandName, isQuesticoBrand } from "../../utils/utils"
import { IPublicRuntimeConfig } from "../../utils/runtimeConfig"
import { isExpert } from "../../utils/typeGuards"

type IProductVariant = "chat" | "call" | "callback"

const brandName = getBrandName()

export function getProductVariant(
  product: IAdvisorProduct | ICheckoutProduct,
  isReservation: boolean
) {
  let productVariant: IProductVariant = "chat"

  if (product.type === "call") {
    productVariant = isReservation ? "callback" : "call"
  }

  return productVariant
}

export function getExpertTrackingProperties(
  listingNo: string,
  displayName: string,
  mandatorNo: BrandsEnum
): ICommonClientTrackingProductProperties {
  const properties: ICommonClientTrackingProductProperties = {
    product_id: `${mandatorNo}-${listingNo}`,
    category: "Expert",
    sku: "",
    name: displayName,
    brand: brandName,
    currency: "EUR",
    url: buildProfileUrl(displayName, listingNo),
  }

  return properties
}

export function getProductTrackingProperties(
  listingNo: string,
  displayName: string,
  getRuntimeConfig: () => IPublicRuntimeConfig,
  product: IAdvisorProduct | ICheckoutProduct,
  isReservation: boolean
) {
  const { MANDATOR_NUMBER } = getRuntimeConfig()
  return {
    ...getExpertTrackingProperties(listingNo, displayName, MANDATOR_NUMBER),
    variant: getProductVariant(product, isReservation),
    price: product.price,
  }
}

export function getCouponsTrackingProperties(
  promotions: IPromotionAdvisor[],
  activePromotion: IPromotionAdvisor | undefined
) {
  return {
    coupon_list: promotions.length
      ? promotions.map(({ id, type, title }, index) => ({
          position: index.toString(),
          coupon_id: id.toString(),
          coupon_title: title,
          coupon_type: type,
        }))
      : null,
    active_coupon_id: !promotions.length
      ? null
      : activePromotion?.id?.toString() || "not_selected",
    active_coupon_title: !promotions.length
      ? null
      : activePromotion?.title || "not_selected",
    active_coupon_type: !promotions.length
      ? null
      : activePromotion?.type || "not_selected",
  }
}

export function getClientTrackingProductStatus(
  advisorStatus: IAdvisorStatus
): ClientTrackingOnlineStatus {
  switch (advisorStatus) {
    case "online":
      return ClientTrackingOnlineStatus.FREE
    case "offline":
      return ClientTrackingOnlineStatus.OFFLINE
    case "busy":
      return ClientTrackingOnlineStatus.BUSY
    default:
      throw new Error("Wrong availability provided")
  }
}

export function mapExpertToClientTrackingTrack(
  expert: IAdvisor,
  mandatorNo: BrandsEnum,
  options?: IClientTrackingProductOptions
): IClientTrackingProduct {
  const { name, listing_number: listingNumber, status, labels } = expert
  // const lastContacted = undefined
  // const lastContacted = (expert as IExpert).contactDateTime

  const clientTrackingProduct: IClientTrackingProduct = {
    ...getExpertTrackingProperties(listingNumber, name, mandatorNo),
    expert_listing_no: listingNumber,
    status: getClientTrackingProductStatus(status),
    /*    last_contacted: lastContacted
      ? formatDate(lastContacted, "yyyy-MM-dd HH:mm:ss", "dd.MM.yyyy")
      : undefined, */
  }

  if (options?.tag) {
    clientTrackingProduct.tag = Object.entries(labels)
      .filter(([_, isActive]) => isActive)
      .map(([label]) => label)
      .join(",")
  }

  if (options?.rating && expert.rating) {
    const { average } = expert.rating
    clientTrackingProduct.rating = average
    // const { ratingValue } = (expert as IExpertEntry).ratingAverage
    // clientTrackingProduct.rating = ratingValue
  }

  return clientTrackingProduct
}

export function mapExpertsToClientTrackingTrack(
  experts: IAdvisor[],
  mandatorNo: BrandsEnum,
  options?: IClientTrackingProductOptions
): TClientTrackingProducts {
  const clientTrackingProducts = experts
    .filter(isExpert)
    .map((expert, index) => {
      const { price } = getCheapestProduct(expert.products) || { price: null }

      const result = {
        ...mapExpertToClientTrackingTrack(expert, mandatorNo, options),
        position: String(index),
        price,
      }

      return result
    })

  return clientTrackingProducts
}

/**
 * @todo refactor for edge cases, when all experts out of view
 */
export const updateExpertsIndexesInView = (
  index: number,
  inView: boolean,
  expertsIndexesInView: IExpertsIndexesInView
): IExpertsIndexesInView => {
  const { min, max } = expertsIndexesInView

  if (inView) {
    return {
      min: Math.min(index, min),
      max: Math.max(index, max),
    }
  }

  return {
    min: min === index ? min + 1 : min,
    max: max === index ? max - 1 : max,
  }
}

export function getProductTrackingData(
  expert: IAdvisor,
  mandatorNo: BrandsEnum,
  listingName?: string
): Record<string, unknown> {
  const { listing_number: listingNumber } = expert
  // Note: the price for call and chat is the same, that's why we can use call or chat here to get the price
  const callProduct = expert.products.find(({ type }) => type === "call")

  const trackingData = {
    ...mapExpertToClientTrackingTrack(expert, mandatorNo, {
      tag: true,
      rating: true,
    }),
    expert_listing_no: listingNumber,
    price: callProduct?.price_sale ?? callProduct?.price,
    list: listingName,
  }

  if (callProduct?.price_sale !== null && expert.promotion)
    return {
      ...trackingData,
      coupon: expert.promotion.type,
    }

  return trackingData
}

export function getPollingListingIds(
  expertsIds: number[],
  expertsIndexesInView: IExpertsIndexesInView,
  extraAmount = 20
): number[] {
  const { min, max } = expertsIndexesInView

  let minIndex = min - extraAmount
  minIndex = minIndex > 0 ? minIndex : 0

  const maxIndex = max + extraAmount

  return expertsIds.slice(minIndex, maxIndex + 1).map((str) => +str)
}

export const getMouseClick = (e: React.MouseEvent): TMouseClick | undefined => {
  switch (e.button) {
    case MouseEventButton.SECONDARY: {
      return "right"
    }
    case MouseEventButton.MAIN: {
      if (e.ctrlKey || e.metaKey) return "left+ctrl"
      if (e.shiftKey) return "left+shift"
      return "left"
    }
    default: {
      return undefined
    }
  }
}

export const trackProductClick = (
  click: TMouseClick | undefined,
  expert: IAdvisor,
  listingName: string | undefined,
  trackEvent: (data: Partial<IDispatchTrackingData>) => void,
  mandatorNo: BrandsEnum
): void => {
  trackEvent({
    eventName: ClientTrackingEventName.PRODUCT_CLICK,
    properties: {
      ...getProductTrackingData(expert, mandatorNo, listingName),
      click,
    },
  })
}

const getExpertMainMethod = (adviceMethods: Array<ICategory>) => {
  const mainMethod = adviceMethods[0] ? adviceMethods[0].name : ""

  return mainMethod
}

const getProfileSeoDescription = (
  displayName: string,
  adviceMethods: Array<ICategory>,
  gender: IAdvisorGender
) => {
  const mainMethod = getExpertMainMethod(adviceMethods)
  const methods = adviceMethods.map((method) => method.name).join(", ")
  let berater = "Berater"

  if (isQuesticoBrand()) {
    berater = gender === "female" ? "Beraterin" : "Berater"
  } else {
    berater = "Berater:in"
  }

  return `${displayName} ist ${berater} für ${mainMethod} bei ${getBrandName()}. ${displayName}s Beratungsmethoden sind ${methods}`
}

const getProfileSeoTitle = (
  displayName: string,
  adviceMethods: Array<ICategory>
) => {
  return `${displayName}: ${getExpertMainMethod(adviceMethods)} | ${brandName}`
}

const getProfileSeoKeywords = (
  displayName: string,
  adviceMethods: Array<ICategory>
) => {
  return `${displayName}, ${getExpertMainMethod(adviceMethods)}, ${brandName}`
}

export const getFullProfileUrl = (
  displayName: string,
  listingNo: string,
  baseUrl: string
) => {
  const profileUrl = buildProfileUrl(displayName, listingNo, false)

  return `${baseUrl}${profileUrl}`
}

export const getProfileSeoConfig = (
  expert: IAdvisorProfile,
  baseUrl: string
) => {
  const { name, listing_number: listingNumber, gender } = expert.advisor
  const { categories } = expert
  return {
    canonical: getFullProfileUrl(name, listingNumber, baseUrl),
    title: getProfileSeoTitle(name, categories),
    keywords: getProfileSeoKeywords(name, categories),
    description: getProfileSeoDescription(name, categories, gender),
  }
}

export const getBestWorstRatings = (
  ratingBuckets: IAdvisorProfile["reviews"]["totals"]
) => {
  const getRatingArray = (buckets: IAdvisorProfile["reviews"]["totals"]) =>
    buckets
      .sort((a, b) => (a.value > b.value ? -1 : 1))
      .filter((item) => item.count > 0)
      .map((item) => item.value)

  const processedRating = getRatingArray(ratingBuckets)
  const worstRating = processedRating[processedRating.length - 1] || ""
  const bestRating = processedRating[0] || ""

  return { bestRating, worstRating }
}

export const getProfileSeoStructuredData = (
  expert: IAdvisorProfile,
  baseUrl: string
) => {
  const {
    name,
    avatar,
    listing_number: listingNumber,
    description,
    products,
    rating,
  } = expert.advisor

  const { bestRating, worstRating } = getBestWorstRatings(expert.reviews.totals)
  const { price } = getCheapestProduct(products) || { price: null }

  return {
    "@context": "http://schema.org",
    "@type": "Product",
    name,
    image: avatar,
    url: getFullProfileUrl(name, listingNumber, baseUrl),
    description: description.replace(/(<([^>]+)>)/gi, ""), // replace html tags
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: rating.average,
      reviewCount: rating.count,
      worstRating,
      bestRating,
    },
    offers: {
      "@type": "Offer",
      priceCurrency: "EUR",
      price,
    },
  }
}
