import { createContext } from "react"

import {
  TTrackingPropertyName,
  TTrackingProperties,
  IDispatchTrackingData,
} from "./types"

export interface ITrackingContext {
  clientTrackingIsReady: boolean
  setProperties: (newProperties: TTrackingProperties) => void
  getProperty: (propertyName: TTrackingPropertyName) => string | undefined
  trackEvent: (data: Partial<IDispatchTrackingData>) => void
}

export default createContext<ITrackingContext>({
  clientTrackingIsReady: false,
  setProperties: () => undefined,
  getProperty: () => undefined,
  trackEvent: () => undefined,
})
