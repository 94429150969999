/**
 * the concept of session tracking is covered in
 * @see https://adviqo.atlassian.net/browse/MISSION-2255
 */

import { v4 as uuidV4 } from "uuid"
import { IncomingMessage } from "http"
import addYears from "date-fns/addYears"
import differenceInMilliseconds from "date-fns/differenceInMilliseconds"

import { ICookieSetter } from "../../types"
import cookies from "../cookies"
import {
  IGetSessionIDOptions,
  ISessionLifetime,
  INewSessionStarted,
  IGetSessionIDResult,
  ICookieData,
  IRequestData,
} from "./types"
import { getRuntimeConfig, IPublicRuntimeConfig } from "../runtimeConfig"
import { isServer } from "../utils"
import { NextRequest } from "next/server"

export const DID_COOKIE_KEY = "qco_did"
export const SESSION_ID_COOKIE_KEY = "qco_sid"

interface ISessionCookieParts {
  uuid: string | undefined
  ts: string | undefined
  landingUrl: string
  landingReferrer: string
  req?: IncomingMessage
}

type IPrepareSessionResult = ISessionCookieParts & INewSessionStarted

const generateUUID = () => uuidV4()

export const getPageUrlAndReferrerServer = (req: NextRequest) => {
  const pageUrl = `${req?.url}`
  const refererUrl = req.headers.get("referer") || ""

  return [pageUrl, refererUrl]
}

export const getPageUrlAndReferrer = (req?: IncomingMessage) => {
  const { BASE_URL } = getRuntimeConfig()

  const pageUrl = isServer()
    ? `${BASE_URL.slice(0, -1)}${req?.url}`
    : window.location.href

  const refererUrl = isServer()
    ? req?.headers.referer || ""
    : window.document.referrer

  return [pageUrl, refererUrl]
}

function getSessionCookieParts(cookieData: ICookieData) {
  if (!cookieData)
    return {
      uuid: undefined,
      ts: undefined,
      landingUrl: "",
      landingReferrer: "",
    }

  const { uuid, sessionCookieParsed } = cookieData
  const [, ts, landingUrl = "", landingReferrer = ""] = sessionCookieParsed

  const sessionParts: ISessionCookieParts = {
    uuid,
    ts,
    landingUrl,
    landingReferrer,
  }

  return sessionParts
}

function createSessionCookie(
  setter: ICookieSetter,
  uuid: string,
  now: number,
  lifetime: ISessionLifetime,
  currentTs: string | undefined,
  getRuntimeConfig: () => IPublicRuntimeConfig,
  landingUrl: string,
  landingReferrer: string
) {
  const ts = currentTs || now.toString()
  const sessionCookieStr = `${uuid}|${ts}|${landingUrl}|${landingReferrer}`

  cookies.create(
    setter,
    SESSION_ID_COOKIE_KEY,
    sessionCookieStr,
    getRuntimeConfig,
    {
      expires: lifetime * 1000,
    }
  )

  return ts
}

function extendSessionCookie(
  setter: ICookieSetter,
  uuid: string,
  now: number,
  lifetime: ISessionLifetime,
  currentTs: string,
  landingUrl: string,
  landingReferrer: string,
  getRuntimeConfig: () => IPublicRuntimeConfig
) {
  cookies.remove(
    setter,
    SESSION_ID_COOKIE_KEY,
    `${uuid}|${currentTs}|${landingUrl}|${landingReferrer}`,
    getRuntimeConfig
  )

  return createSessionCookie(
    setter,
    uuid,
    now,
    lifetime,
    currentTs,
    getRuntimeConfig,
    landingUrl,
    landingReferrer
  )
}

function createSessionUUIDCookie(
  setter: ICookieSetter,
  now: number,
  getRuntimeConfig: () => IPublicRuntimeConfig
) {
  const uuid = generateUUID()

  cookies.create(setter, DID_COOKIE_KEY, uuid, getRuntimeConfig, {
    expires: differenceInMilliseconds(addYears(now, 10), now),
  })

  return uuid
}

function createUniqueVisitor(
  setter: ICookieSetter,
  now: number,
  lifetime: ISessionLifetime,
  getRuntimeConfig: () => IPublicRuntimeConfig,
  requestData: IRequestData,
  currentUUID?: string | undefined
): ISessionCookieParts {
  const uuid =
    currentUUID || createSessionUUIDCookie(setter, now, getRuntimeConfig)

  const { url: landingUrlNew, refferer: landingReferrerNew } = requestData
  // const [landingUrlNew, landingReferrerNew] = getPageUrlAndReferrer(req)

  const parts: ISessionCookieParts = {
    ts: createSessionCookie(
      setter,
      uuid,
      now,
      lifetime,
      undefined,
      getRuntimeConfig,
      landingUrlNew,
      landingReferrerNew
    ),
    uuid,
    landingUrl: landingUrlNew,
    landingReferrer: landingReferrerNew,
  }

  return parts
}

/**
 * session could be prepared at any moment:
 * - during SSR
 * - at any moment in browser
 * - as many times as it's called
 *
 * Responsibility are:
 * - create unique visitor, if there is no qco_did AND qco_sid in cookie
 * - start a new session, if there is no qco_sid in cookie
 * - prolong current session at any time otherwise
 */
function prepare(
  { lifetime, setter, cookieData, requestData }: IGetSessionIDOptions,
  getRuntimeConfig: () => IPublicRuntimeConfig
) {
  const { uuid, ts, landingUrl, landingReferrer } = getSessionCookieParts(
    cookieData
  )

  const now = Date.now()

  let result: IPrepareSessionResult = {
    ts,
    uuid,
    newSessionStarted: false,
    landingUrl,
    landingReferrer,
  }

  if (!uuid) {
    result = {
      ...createUniqueVisitor(
        setter,
        now,
        lifetime,
        getRuntimeConfig,
        requestData
      ),
      newSessionStarted: true,
    }
  } else if (!ts) {
    const { url: landingUrlNew, refferer: landingReferrerNew } = requestData

    result = {
      ...result,
      ts: createSessionCookie(
        setter,
        uuid,
        now,
        lifetime,
        ts,
        getRuntimeConfig,
        landingUrlNew,
        landingReferrerNew
      ),
      newSessionStarted: true,
      landingUrl,
    }
  } else {
    result.ts = extendSessionCookie(
      setter,
      uuid,
      now,
      lifetime,
      ts,
      landingUrl,
      landingReferrer,
      getRuntimeConfig
    )
  }

  return result
}

function getSessionID(
  opts: IGetSessionIDOptions,
  getRuntimeConfig: () => IPublicRuntimeConfig
): IGetSessionIDResult {
  try {
    const {
      ts,
      uuid,
      newSessionStarted,
      landingUrl,
      landingReferrer,
    } = prepare(opts, getRuntimeConfig)
    const sessionId = `${uuid}|${ts}`

    return { sessionId, newSessionStarted, landingUrl, landingReferrer }
  } catch (error) {
    console.error(error, opts.cookieData)

    return {
      sessionId: "",
      newSessionStarted: false,
      landingUrl: "",
      landingReferrer: "",
    }
  }
}

export default {
  getSessionID,
}
