import {
  IJWTProfile,
  LOCAL_STORAGE_KEYS,
  BrandsEnum,
  IGetFPData,
} from "../../types"
import { TUserStatus } from "../../utils/tracking/types"
import { getBrowserDetails } from "../browserFeatures/helpers"
import { IDispatchTrackingData, IClientTrackingContext } from "./types"
import { storage } from "../../utils/localStorage"
import { apiRequest, QProxyEndpoint } from "../../utils/rest"
import cachedToken from "../../utils/graphql/cachedToken"
import {
  getRuntimeConfig,
  IPublicRuntimeConfig,
} from "../../utils/runtimeConfig"
import { getBrandName } from "../../utils/utils"
import { getSessionIDBrowser } from "../../utils/session"

export interface IFingerprintsTrackingPayload {
  memberNo?: string
  mandatorNo: BrandsEnum
  visitorId: string | undefined
}

export function createSharedTrackingFields():
  | IClientTrackingContext
  | undefined {
  if (typeof window === "undefined") return undefined

  const UAParser = getBrowserDetails(window.navigator.userAgent)
  return {
    screen: {
      width: window.screen.width.toString(),
      height: window.screen.height.toString(),
    },
    os: {
      name: UAParser?.os.name || "",
      version: UAParser?.os.version || "",
    },
  }
}

async function dispatchClientTrackingEvent(
  data: IDispatchTrackingData
): Promise<void> {
  const {
    method = "track",
    eventName,
    userId,
    properties,
    traits,
    context: contextInitial,
    title,
    callback,
    isLoggedIn,
  } = data

  const isAuthenticated = Boolean(cachedToken.token)

  if (!window.rudderanalytics || !method) return

  const userStatus: TUserStatus = isAuthenticated ? "logged_in" : "logged_out"
  const brand = getBrandName()
  const { sessionId } = getSessionIDBrowser(getRuntimeConfig)

  const context = {
    ...contextInitial,
    session_id: sessionId || "",
  }

  switch (method) {
    case "identify": {
      const args = [
        {
          brand,
          ...traits,
          user_status: userStatus,
          ...(typeof isLoggedIn === "boolean" ? { isLoggedIn } : {}),
        },
        context,
      ]

      // Note: if userId is `undefined`, we should not pass it, otherwise its empty value will override stored `userId` in the tracking events
      const argsFinal = userId === undefined ? args : [userId, ...args]
      window.rudderanalytics.identify(...argsFinal)
      break
    }
    case "page": {
      if (eventName !== undefined) {
        window.rudderanalytics.page(
          eventName,
          {
            title,
            brand,
            user_status: userStatus,
          },
          context
        )
      }
      break
    }
    default: {
      if (eventName !== undefined) {
        window.rudderanalytics[method](
          eventName,
          {
            brand,
            ...properties,
            user_status: userStatus,
          },
          context,
          callback
        )
      }
    }
  }
}

export function customDispatch(data = {} as IDispatchTrackingData): void {
  const { destination = "clientTracking" } = data

  switch (destination) {
    case "clientTracking": {
      dispatchClientTrackingEvent(data)
      break
    }
    default:
      console.warn(`Unknown destination: '${destination}'`)
  }
}

export function setCheckoutId(checkoutId: string): void {
  storage.setItem(LOCAL_STORAGE_KEYS.CLIENT_TRACKING_CHECKOUT_ID, checkoutId)
}

export function getCheckoutId(): string | null {
  return storage.getItem<string>(LOCAL_STORAGE_KEYS.CLIENT_TRACKING_CHECKOUT_ID)
}

export function clearCheckoutId(): void {
  storage.cleanUp(LOCAL_STORAGE_KEYS.CLIENT_TRACKING_CHECKOUT_ID)
}

export async function fingerprintsTracking(
  profile: IJWTProfile,
  getRuntimeConfig: () => IPublicRuntimeConfig,
  getFPData: IGetFPData
): Promise<string | undefined> {
  const { FINGERPRINTS_IS_ENABLED, MANDATOR_NUMBER } = getRuntimeConfig()

  if (!FINGERPRINTS_IS_ENABLED || !MANDATOR_NUMBER) return undefined

  const { visitorId } = await getFPData()
  const body: IFingerprintsTrackingPayload = {
    mandatorNo: MANDATOR_NUMBER,
    visitorId,
    ...(profile?.user_id && { memberNo: String(profile.user_id) }),
  }

  apiRequest(
    {
      url: QProxyEndpoint.FINGERPRINTS_ATTEMPTS,
      service: "qproxy",
      method: "PUT",
      body,
    },
    getRuntimeConfig
  ).catch(console.error)

  return visitorId
}
