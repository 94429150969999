import { createContext } from "react"

export type IIntegrations = Record<string, boolean>

export interface IConsentState {
  usercentricsIsReady: boolean
  /**
   * consent is required (no consent given or resurface). Value depends on usercentrics UI being initialized.
   * boolean: true if consent is required, false if not, null if information is not available (yet)
   */
  consentIsRequired: boolean | undefined | null
  /**
   * either explicitely or implicitely
   */
  clientTrackingConsentIsGiven: boolean
  integrations: IIntegrations | undefined
}

export default createContext<IConsentState>({
  usercentricsIsReady: false,
  clientTrackingConsentIsGiven: false,
  consentIsRequired: null,
  integrations: undefined,
})
