import {
  BrandsEnum,
  LOCAL_STORAGE_KEYS,
  Environment,
  IGetFPData,
} from "../../types"
import { IPublicRuntimeConfig } from "../runtimeConfig"
import { storage } from "../localStorage"
import { apiRequest, AWSEndpoint } from "../rest"

interface IPerisistableData {
  tsStart?: number
}

interface ITrackingAWSPayload extends IPerisistableData {
  visitorId: string | undefined
  mandatorNo: BrandsEnum
  ua: string
  tsEnd: number
  ip: string | undefined
  env?: string
  initPath: "qde_nfe" | "viv_nfe"
  // eslint-disable-next-line camelcase
  qco_fp: unknown
}

function clean(): void {
  storage.cleanUp(LOCAL_STORAGE_KEYS.AWS_TRACKING_DATA)
}

function getPersistedData() {
  const rawSaved = storage.getItem<IPerisistableData>(
    LOCAL_STORAGE_KEYS.AWS_TRACKING_DATA,
    true
  )

  return rawSaved || {}
}

const X_INIT_PATH: Record<BrandsEnum, ITrackingAWSPayload["initPath"]> = {
  [BrandsEnum.QUESTICO]: "qde_nfe",
  [BrandsEnum.VIVERSUM_GERMANY]: "viv_nfe",
}

async function awsTracking(
  getRuntimeConfig: () => IPublicRuntimeConfig,
  getFPData: IGetFPData
): Promise<void> {
  const {
    AWS_FRAUD_DETECTOR_IS_ENABLED,
    MANDATOR_NUMBER,
    ENVIRONMENT,
    AWS_FRAUD_DETECTOR_KEY,
    FINGERPRINTS_IS_ENABLED,
  } = getRuntimeConfig()

  if (!AWS_FRAUD_DETECTOR_IS_ENABLED || !MANDATOR_NUMBER) return

  const { ip = undefined, visitorId = undefined } = FINGERPRINTS_IS_ENABLED
    ? await getFPData()
    : {}

  const persistedData = getPersistedData()

  const body: ITrackingAWSPayload = {
    visitorId,
    mandatorNo: MANDATOR_NUMBER,
    ua: window.navigator.userAgent,
    tsEnd: new Date().getTime(),
    ip,
    initPath: X_INIT_PATH[MANDATOR_NUMBER],
    qco_fp: storage.getItem(LOCAL_STORAGE_KEYS.FINGERPRINTJS_GET_RESULT, true),
    ...persistedData,
    ...(ENVIRONMENT !== Environment.PRODUCTION && {
      env: "qa",
    }),
  }

  apiRequest(
    {
      url: AWSEndpoint.FRAUND_PREDICTION,
      service: "aws-fraud",
      method: "POST",
      headers: {
        "x-api-key": AWS_FRAUD_DETECTOR_KEY,
      },
      body,
    },
    getRuntimeConfig
  )
    .catch(console.error)
    .finally(clean)
}

function persistTrackingData(data: IPerisistableData): void {
  const saved = getPersistedData()
  const next = {
    ...saved,
    ...data,
  }

  storage.setItem(LOCAL_STORAGE_KEYS.AWS_TRACKING_DATA, next)
}

function dataAlreadyPersisted(): boolean {
  const data = getPersistedData()

  return Boolean(data.tsStart)
}

export default {
  track: awsTracking,
  persist: persistTrackingData,
  clean,
  dataAlreadyPersisted,
}
