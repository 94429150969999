import {
  IMultiLevelSlugNode,
  IGroupedItems,
  ILink,
  IListingNumber,
  ILinkedFromPageItem,
  ISlugOrArticleOrPage,
} from "../types"
import { ISideNavigation } from "../graphql/contentful-schema"
import { isArticle, isLink } from "./typeGuards"
import { log } from "./logger/serverLogger"

const buildSlug = (item: ISlugOrArticleOrPage): string => {
  return item.parentSlug
    ? `${buildSlug(item.parentSlug)}/${item.slug}`
    : item.slug
}

const buildUrlInfoMessage = (item: ISlugOrArticleOrPage, id?: string) =>
  `building url for article. id: "${id}", slug: "${JSON.stringify(item)}"`

const buildUrl = (item: ISlugOrArticleOrPage, id?: string) => {
  const urlForArticle = isArticle(item) && id

  if (urlForArticle) {
    const infoMessage = buildUrlInfoMessage(item, id)
    if (typeof window === "undefined") {
      log(undefined, "info", {
        message: infoMessage,
      })
    } else {
      console.warn(infoMessage)
    }
  }

  return urlForArticle
    ? `${`/${buildSlug(item)}`}-${id}.html`
    : `/${buildSlug(item)}`
}

export const slugify = (value: string) => {
  return value
    .trim()
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/--+/g, "-")
    .replace(/_+/g, "-")
    .replace(/[^a-z0-9-]/g, "")
}
export function buildLinkUrl(
  item: ILink | ISlugOrArticleOrPage,
  id?: string
): string {
  return isLink(item) ? item.url : buildUrl(item, id)
}

export const buildProfileUrl = (
  displayName: string,
  listingNo: IListingNumber,
  withLeadingSlash = true
): string => {
  const firstSymbol = withLeadingSlash ? "/" : ""

  return `${firstSymbol}berater/${slugify(displayName)}/${listingNo}`
}

const findSelectedChild = (
  navItem: IMultiLevelSlugNode,
  blacklist: string[] | null,
  currentPageId?: string
) =>
  navItem.linkedFrom?.pageCollection.items.find((childItem) => {
    if (blacklist && blacklist.length > 0) {
      return (
        !blacklist.includes(childItem.sys.id) &&
        childItem.sys.id === currentPageId
      )
    }
    return childItem.sys.id === currentPageId
  })

export const getNavigationData = (
  sideNavigationItems: Array<ISideNavigation>,
  currentPageId?: string
) => {
  let selectedChildItem: ILinkedFromPageItem | undefined

  const groupedItems = sideNavigationItems.reduce(
    (accumulator: IGroupedItems, sideNavItem) => {
      const { items } = sideNavItem.rootSlugsCollection || { items: [] }

      const blacklist =
        sideNavItem.blacklistCollection &&
        sideNavItem.blacklistCollection.items.map((i) => i.sys.id)

      const filteredItems =
        blacklist && blacklist.length > 0
          ? items.filter(
              (item) => item.page && !blacklist.includes(item.page.sys.id)
            )
          : items

      filteredItems.forEach((current) => {
        if (!selectedChildItem) {
          selectedChildItem = findSelectedChild(
            current,
            blacklist,
            currentPageId
          )
        }
      })

      const navItems = filteredItems.map((item) => ({
        ...item,
        linkedFrom: {
          pageCollection: {
            items:
              (item.linkedFrom &&
                item.linkedFrom.pageCollection.items
                  .filter((page) => {
                    if (blacklist && blacklist.length > 0) {
                      return (
                        !blacklist.includes(page.sys.id) &&
                        item.page &&
                        page.sys.id !== item.page.sys.id
                      )
                    }
                    return item.page && page.sys.id !== item.page.sys.id
                  })
                  .sort((a, b) => {
                    if (a.rank && b.rank) {
                      return a.rank - b.rank
                    }
                    return NaN
                  })) ||
              [],
          },
        },
      }))

      if (navItems.length > 0) {
        accumulator[sideNavItem.headline || "ALL"] = {
          navItems,
          headlinePage: sideNavItem.headlinePage,
        }
      }
      return accumulator
    },
    {}
  )

  return {
    navigationItems: groupedItems,
    selectedChildItem,
  }
}

export const matchArticleSlug = (slug: string): RegExpMatchArray | null => {
  const vals = slug.split("-")
  const lastStr = vals[vals.length - 1]

  return lastStr.match(/(^[a-zA-Z0-9]{20,64})\.([a-zA-Z]{3,10})/)
}

interface IPreviewSlugResult {
  pagePreview: boolean
  articlePreview: boolean
  previewId: string | undefined
}

export const isPreviewSlug = (slug: string): IPreviewSlugResult => {
  const vals = slug.split("-")
  const lastStr = vals[vals.length - 1]

  const result: IPreviewSlugResult = {
    pagePreview: false,
    articlePreview: false,
    previewId: undefined,
  }

  if (lastStr === "page.preview") {
    result.pagePreview = true
    result.previewId = vals[0]
  } else if (lastStr === "article.preview") {
    result.articlePreview = true
    result.previewId = vals[0]
  }

  return result
}
