import { setContext } from "@apollo/client/link/context"

class CachedToken {
  private _token: string | undefined
  private _pendingToken: string | undefined

  get token() {
    return this._token
  }

  set token(token: string | undefined) {
    this._token = token
  }

  reset() {
    this._token = undefined
  }

  get pendingToken() {
    return this._pendingToken
  }

  set pendingToken(pending: string | undefined) {
    this._pendingToken = pending
  }

  makeAuthFlowLink() {
    const link = setContext(() => {
      return { cachedToken: this._token }
    })

    return link
  }
}
/*let cachedToken*/

/*
// need fo development
// https://stackoverflow.com/questions/75272877/how-to-prevent-next-js-from-instantiating-a-singleton-class-object-multiple-time
if (process.env.NODE_ENV === "production") {
  cachedToken = new CachedToken()
} else {
  // @ts-ignore
  if (!global.cachedToken) {
    // @ts-ignore
    global.cachedToken = new CachedToken()
  }
  // @ts-ignore
  cachedToken = global.cachedToken
}
*/
const cachedToken = new CachedToken()

export default cachedToken
