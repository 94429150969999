import { AxiosError } from "axios"
import { IncomingMessage } from "http"
import { AuthEndpoints } from "../../hooks/authentication/types"
import { CommonErrors, Cookie } from "../../types"
import { isServer } from "../utils"
import cookies from "../cookies"
import { getRuntimeConfig } from "../runtimeConfig"
import { cookieSetter } from "../../hooks/authentication/utils"

export const isAuthError = (error: AxiosError | Error | undefined) => {
  if (!error || !(error instanceof AxiosError)) {
    return false
  }

  const status = error.response?.status
  const apiUrl = error.config?.url

  if (!apiUrl) {
    return false
  }

  const { ADVICE_PLATFORM_API_VERSION } = getRuntimeConfig()
  const url = new URL(apiUrl)

  const [path] = url.pathname
    .split(`/api/${ADVICE_PLATFORM_API_VERSION}/`)
    .filter(Boolean)

  return (
    status === CommonErrors.UNAUTHORIZED &&
    path !== AuthEndpoints.LOGIN &&
    path !== AuthEndpoints.LOGOUT &&
    path !== AuthEndpoints.REFRESH
  )
}

export const getFingerprintHeader = (req?: IncomingMessage): string => {
  const defaultFingerprint = "1"
  const { cookie } = req?.headers || {}

  let fingerprintValue = defaultFingerprint

  if (!isServer()) {
    const savedFingerprint = cookies.get(
      window?.document?.cookie,
      Cookie.ANONYMOUS_ID
    )

    if (savedFingerprint && savedFingerprint !== defaultFingerprint) {
      fingerprintValue = savedFingerprint
    } else {
      fingerprintValue =
        window?.rudderanalytics?.getAnonymousId() || defaultFingerprint

      cookies.create(
        cookieSetter,
        Cookie.ANONYMOUS_ID,
        fingerprintValue,
        getRuntimeConfig
      )
    }

    return fingerprintValue
  }

  fingerprintValue =
    cookies.get(cookie, Cookie.ANONYMOUS_ID) || defaultFingerprint

  return fingerprintValue
}

type StorageKey = "local_storage" | "session_storage" | "cookie_storage"

export const getTrackingKeysList = (storageKey: StorageKey) => {
  const { X_TRACKING_KEYS } = getRuntimeConfig()
  const keysStr = X_TRACKING_KEYS?.trim()

  if (!keysStr) return []

  try {
    const data = JSON.parse(keysStr)
    const valuesStr = data[storageKey]

    if (!valuesStr) return []

    return valuesStr.split(",")
  } catch (e) {
    console.error(e)
    return []
  }
}
