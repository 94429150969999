import jwtDecode from "jwt-decode"

import {
  IJWTProfile,
  TAuthenticationAction,
  PageSlug,
  TProtectedRoutes,
  ICookieSetter,
  Cookie,
} from "../../types"
import { IAuthenticationState } from "./types"
import { assertIsDefined } from "../../utils/utils"
import cachedToken from "../../utils/graphql/cachedToken"
import cookies from "../../utils/cookies"
import { IPublicRuntimeConfig } from "../../utils/runtimeConfig"

export const extractUserId = (subject: string | undefined) => {
  assertIsDefined(subject)

  const splittedSub = subject.split(":")
  const id = splittedSub[splittedSub.length - 1]

  return id
}

export const isPageProtected = (
  protectedPaths: TProtectedRoutes,
  pathname: string
): boolean => {
  let result = false
  // TODO:: Refactor this loop
  // eslint-disable-next-line
  for (const path of protectedPaths) {
    if (path.split("/")[1] === pathname.split("/")[1]) {
      result = true
      break
    }
  }

  return result
}

export function getMemberType(profile: IJWTProfile): "user" | "expert" {
  return profile.profile_id === "advisor" ? "expert" : "user"
}

function makeParseJwtProfileFromToken() {
  let cachedJwtProfile: IJWTProfile | null = null
  let prevToken: string | null = null

  return (token: string | undefined): IJWTProfile | null => {
    if (!token) return null

    if (cachedJwtProfile && prevToken === token) {
      return cachedJwtProfile
    }

    try {
      cachedJwtProfile = jwtDecode(token)

      prevToken = token

      return cachedJwtProfile
    } catch (e) {
      console.error("Error while token parsing: ", e)
      return null
    }
  }
}

export const parseJwtProfileFromToken = makeParseJwtProfileFromToken()

export function getAuthFormPageSlug(
  authAction: TAuthenticationAction
): PageSlug.REGISTRATION | PageSlug.LOGIN {
  return authAction === "register" ? PageSlug.REGISTRATION : PageSlug.LOGIN
}

export function shouldRefreshToken(
  isTokenRefreshing: boolean,
  refreshToken: string | undefined
): boolean {
  return !isTokenRefreshing && refreshToken !== undefined
}

function sideEffect(token: string): void {
  cachedToken.token = token
}

function cachedStateOnServer(defaultState: IAuthenticationState) {
  let cachedState: IAuthenticationState
  let prevToken: string

  return (token: string): IAuthenticationState => {
    if (cachedState && token === prevToken) {
      return cachedState
    }

    const profile = parseJwtProfileFromToken(token)
    if (!profile) return defaultState

    const state = {
      token,
      refreshToken: undefined,
      profile,
      userId: profile.user_id?.toString(),
      isAuthenticated: true,
    }

    prevToken = token
    cachedState = state

    return state
  }
}

export function getAuthStateOnServer(
  defaultState: IAuthenticationState,
  token?: string
): IAuthenticationState {
  if (token) {
    const getStateFromToken = cachedStateOnServer(defaultState)

    return getStateFromToken(token)
  }

  return defaultState
}

export function getAuthStateOnClient(
  defaultState: IAuthenticationState
): IAuthenticationState {
  const token = cookies.get(window.document.cookie, Cookie.TOKEN)
  const refreshToken = cookies.get(window.document.cookie, Cookie.REFRESH_TOKEN)

  if (token && refreshToken) {
    const profile = parseJwtProfileFromToken(token)

    if (!profile) return defaultState
    const userId = profile.user_id?.toString()

    sideEffect(token)

    return {
      token,
      refreshToken,
      profile,
      userId,
      isAuthenticated: true,
    }
  }

  return defaultState
}

export const cookieSetter = (value: string): void => {
  window.document.cookie = value
}

export const createAuthCookies = (
  token: string,
  refreshToken: string,
  getRuntimeConfig: () => IPublicRuntimeConfig,
  setter: ICookieSetter = cookieSetter
): void => {
  const getMaxAge = (payload: IJWTProfile | null) =>
    payload?.exp ? (payload?.exp * 1000 - Date.now()) / 1000 : undefined

  const payloadToken: IJWTProfile | null = jwtDecode(token)

  cookies.create(setter, Cookie.TOKEN, token, getRuntimeConfig, {
    maxAge: getMaxAge(payloadToken),
  })

  const payloadRefreshToken: IJWTProfile | null = jwtDecode(refreshToken)

  cookies.create(setter, Cookie.REFRESH_TOKEN, refreshToken, getRuntimeConfig, {
    maxAge: getMaxAge(payloadRefreshToken),
  })
}
