import { ParsedUrlQuery, stringify } from "querystring"
import { IncomingMessage } from "http"
import axios, { AxiosError, AxiosRequestConfig } from "axios"
import { buildAxiosFetch } from "@lifeomic/axios-fetch"

import { CustomHeader, SomeRequired } from "../../types"
import cachedToken from "../graphql/cachedToken"

import { IPublicRuntimeConfig } from "../runtimeConfig"

import { getFingerprintHeader } from "./utils"
import { strings } from "../../ui-lib/Auth/strings"

type TRemoteService = "qproxy" | "firebase" | "aws-fraud" | "user-service"

export enum QProxyEndpoint {
  GET_HEALTH = "api/health",
  RESERVATIONS = "api/reservations",
  RESERVE_CALL = "api/reserve-call",
  CALL_TERMINAL_NO = "api/call-terminal-no",
  FINGERPRINTS_ATTEMPTS = "api/fingerprints/attempts",
  FINGERPRINTS_ATTEMPT = "api/fingerprints/attempt",
  FINGERPRINTS_WHITELIST = "api/fingerprints/whitelist-me",
}

export enum AWSEndpoint {
  FRAUND_PREDICTION = "default/lambda-fraud-detector",
}

type TRestEndpoints = QProxyEndpoint | AWSEndpoint

interface IRequestOptions extends Omit<RequestInit, "method" | "body"> {
  url: TRestEndpoints | string
  service: TRemoteService
  /**
   * `GET` by default
   */
  method?: "GET" | "POST" | "PUT" | "DELETE"
  query?: Partial<ParsedUrlQuery>
  body?: Record<string, any> | string
  // when we want to return error json object and not throw an error
  returnErrorJSON?: boolean
}

export interface IApiErrorData {
  code: number
  data: Record<string, unknown> | unknown
  message: string
}

interface IApiErrorValidationData {
  errors: Record<string, [string]>
}

export interface ISimplifiedApiError {
  reason: "system" | string
  message: string
  code: number
  isError?: boolean
}

const makeDefaultErrorMessage = (baseUrl: string, response: Response) =>
  `API error at '${baseUrl}'. Status '${response.status}'`

function getBaseUrl(
  service: TRemoteService,
  options: IRequestOptions,
  getRuntimeConfig: () => IPublicRuntimeConfig
) {
  const { QPROXY_BASE_URL, AWS_FRAUD_DETECTOR_URL } = getRuntimeConfig()

  switch (service) {
    case "qproxy":
      return QPROXY_BASE_URL

    case "aws-fraud":
      return AWS_FRAUD_DETECTOR_URL

    default:
      throw new Error(
        `'baseUrl' is not defined for options: ${JSON.stringify(options)}`
      )
  }
}

/**
 * example of how to mock buildAxiosFetch
 *     jest.spyOn(axios, "request").mockResolvedValueOnce({
 *       data: 1,
 *       status: 200,
 *       statusText: "text",
 *       headers: {
 *         h1: 1,
 *       },
 *     })
 */
export const axiosFetch = buildAxiosFetch(axios)

const buildUrlWithParams = (
  options: IRequestOptions,
  getRuntimeConfig: () => IPublicRuntimeConfig
): [string, string, RequestInit] => {
  const {
    url,
    service,
    method = "GET",
    body,
    headers,
    query,
    ...rest
  } = options

  const baseUrl = getBaseUrl(service, options, getRuntimeConfig)

  const queryString = query ? `?${stringify(query)}` : ""
  const finalUrl = `${baseUrl}/${url}${queryString}`
  const finalHeaders: HeadersInit = {
    "content-type": "application/json",
    ...headers,
  }

  const params: RequestInit = {
    method,
    headers: finalHeaders,
    ...rest,
  }

  if (body !== undefined) {
    params.body = typeof body === "string" ? body : JSON.stringify(body)
  }

  return [baseUrl, finalUrl, params]
}

export type IRestServices =
  | "advice"
  | "fapcas"
  | "personalHoroscope"
  | "viversum"

export const fetchWithConfig = async <T = Record<string, unknown>>(
  config: SomeRequired<AxiosRequestConfig, "url"> & {
    withAuthHeader?: boolean
  },
  getRuntimeConfig: () => IPublicRuntimeConfig,
  service: IRestServices = "advice",
  req?: IncomingMessage
): Promise<T> => {
  const defaultConfig = {
    method: "GET",
  }

  const { withAuthHeader = true } = config

  const getURL = (
    baseURL: string,
    service: IRestServices,
    getRuntimeConfig: () => IPublicRuntimeConfig
  ) => {
    const {
      ADVICE_PLATFORM_API,
      ADVICE_PLATFORM_API_VERSION,
    } = getRuntimeConfig()

    if (service === "advice") {
      return `${ADVICE_PLATFORM_API}/${ADVICE_PLATFORM_API_VERSION}/${baseURL}`
    }
    return baseURL
  }

  const getHeaders = async (
    baseHeaders: AxiosRequestConfig["headers"],
    service: IRestServices
  ) => {
    const newHeaders = baseHeaders || {}
    if (service === "advice") {
      newHeaders["X-Fingerprint"] = getFingerprintHeader(req)
      const { token } = cachedToken
      if (token && withAuthHeader) {
        newHeaders[CustomHeader.AUTH] = `Bearer ${token}`
      }
    }
    return newHeaders
  }

  return axios
    .request({
      ...defaultConfig,
      ...config,
      headers: await getHeaders(config.headers, service),
      url: getURL(config.url, service, getRuntimeConfig),
    })
    .then((res) => res.data)
    .then((res) => res?.data || res)
}

export async function apiRequest<T extends Record<string, any>>(
  options: IRequestOptions,
  getRuntimeConfig: () => IPublicRuntimeConfig,
  customResponseHandler?: (res: Response) => Response | Promise<any>
) {
  const [baseUrl, finalUrl, params] = buildUrlWithParams(
    options,
    getRuntimeConfig
  )
  const responsePromise = axiosFetch(finalUrl, params)
  const { returnErrorJSON } = options

  if (customResponseHandler) {
    return responsePromise.then(customResponseHandler)
  }

  return responsePromise
    .then((response) => {
      if (
        !response.ok &&
        response.headers.get("content-type")?.includes("text/html")
      ) {
        throw new Error(makeDefaultErrorMessage(baseUrl, response))
      }

      return Promise.all([response, response.json()])
    })
    .then(([response, json]) => {
      if (!response.ok) {
        if (returnErrorJSON && json) return json

        throw new Error(
          json.message || makeDefaultErrorMessage(baseUrl, response)
        )
      }

      return json as T
    })
}

export function getCodeAndMessageFromApiError(
  response: AxiosError<IApiErrorData>,
  defaultErrorMessage: string = strings.DEFAULT_ERROR_MESSAGE
): ISimplifiedApiError {
  const { data: resData } = response.response || {}
  const { code = 0, data: errorData } = resData || {}

  let message = defaultErrorMessage
  let reason = "system"

  if (code === 422 && errorData) {
    const { errors } = errorData as IApiErrorValidationData
    reason = Object.keys(errors)?.[0]
    message = Object.values(errors)?.[0]?.[0]
  }

  if (code.toString().startsWith("5")) {
    if (defaultErrorMessage) message = defaultErrorMessage
    if (resData?.message) message = resData.message
  }

  return { reason, message, code, isError: true }
}

export type IDefaultRequestConfig = Parameters<typeof fetchWithConfig>[0]

export interface IFetchWithConfigOptions<T = IDefaultRequestConfig> {
  arg: T
  headers?: Record<string, string>
}
