import { IPublicRuntimeConfig } from "../runtimeConfig"
import { IGetSessionIDResult, IGetSessionIDOServerOptions } from "./types"
import session, {
  DID_COOKIE_KEY,
  getPageUrlAndReferrer,
  SESSION_ID_COOKIE_KEY,
} from "./session"
import { isServer } from "../utils"
import cookies from "../cookies"

export function getSessionIDBrowser(
  getRuntimeConfig: () => IPublicRuntimeConfig
): IGetSessionIDResult {
  if (isServer())
    throw new Error("getSessionIDBrowser is expeceted to be called in browser")

  const { TRACKED_SESSION_EXPIRES_IN = 3600 } = getRuntimeConfig()

  // a bit duplicating from getXTrackingHeader because this func get called also for tracking
  const [url, refferer] = getPageUrlAndReferrer()
  const requestData = {
    url,
    refferer,
  }

  const currentCookie = window.document.cookie
  const cookieData = {
    sessionCookieParsed:
      cookies.get(currentCookie, SESSION_ID_COOKIE_KEY)?.split("|") || [],
    uuid: cookies.get(currentCookie, DID_COOKIE_KEY),
  }

  const sessionData = session.getSessionID(
    {
      setter: (value: string) => {
        window.document.cookie = value
      },
      lifetime: TRACKED_SESSION_EXPIRES_IN,
      requestData,
      cookieData,
    },
    getRuntimeConfig
  )

  return sessionData
}

export function getSessionIDServer(
  { setter, cookieData, requestData }: IGetSessionIDOServerOptions,
  getRuntimeConfig: () => IPublicRuntimeConfig
): IGetSessionIDResult {
  if (!isServer())
    throw new Error("getSessionIDServer is expeceted to be called on server")

  if (!setter || typeof setter !== "function")
    throw new Error(
      "getSessionIDServer is expeceted to be called with cookie setter"
    )

  const { TRACKED_SESSION_EXPIRES_IN = 3600 } = getRuntimeConfig()

  return session.getSessionID(
    {
      setter,
      lifetime: TRACKED_SESSION_EXPIRES_IN,
      cookieData,
      requestData,
    },
    getRuntimeConfig
  )
}
