/* eslint-disable camelcase */
import { Method } from "axios"
import { ReactNode } from "react"
import { IJWTProfile, TProtectedRoutes } from "../../types"

export enum AuthEndpoints {
  LOGIN = "login",
  REGISTER = "register",
  RESET_PASSWORD = "password/reset",
  VALIDATE_RESET_PASSWORD_LINK = "password/reset/validate",
  REFRESH = "token/refresh",
  LOGOUT = "logout",
}

export interface IAuthenticationProviderProps {
  protectedPaths: TProtectedRoutes
  token?: string
  children?: ReactNode
}

export interface IAuthenticationState {
  userId: string | undefined
  token: string | undefined
  refreshToken: string | undefined
  isAuthenticated: boolean
  profile: IJWTProfile | null
}

export enum AuthAction {
  TOKEN = "TOKEN",
  USER_ID = "USER_ID",
  RESET_STATE = "RESET_STATE",
  SET_NOT_AUTHENTICATED = "SET_NOT_AUTHENTICATED",
}

export type TAuthAction =
  | {
      type: AuthAction.TOKEN
      token: string
      refreshToken: string
      userId: string
      profile: IJWTProfile
    }
  | { type: AuthAction.RESET_STATE }

export interface IAuthConfig {
  payload: Record<string, any> | undefined
  path: string
  method?: Method
  withAuthHeader?: boolean
  headers?: Record<string, string>
}

export interface IAuthTokenResponse {
  auth_token: {
    access_token: string
    expires_in: number
    refresh_token: string
  }
}
