import cookie, { CookieSerializeOptions } from "cookie"

import { Environment, ICookieSetter } from "../types"
import { IPublicRuntimeConfig } from "./runtimeConfig"

interface ICookieSerializeOptions
  extends Omit<CookieSerializeOptions, "expires"> {
  /**
   * amount of ms when cookie will expire, starting from Date.now()
   * set to negative number to expire immediately
   * */
  expires?: number
}

/**
 * environment-agnostic utility. Works in browser & in SSR
 * returns all the cookies or value by key
 * Note: in browser not all cookies could be readable (check out httpOnly flag)
 */
function get(
  cookies: string | undefined | null,
  key: string
): string | undefined
function get(cookies: string): Record<string, string> | undefined
function get(
  cookies: string | undefined | null,
  key?: string
): Record<string, string> | string | undefined {
  if (!cookies) return undefined

  const parsed = cookie.parse(cookies)

  return key ? parsed[key] : parsed
}

/**
 * checks if value exists for given key
 */
const has = (cookie: string | undefined, key: string): boolean =>
  Boolean(get(cookie, key))

function create(
  setter: ICookieSetter,
  key: string,
  value: string,
  getRuntimeConfig: () => IPublicRuntimeConfig,
  options: ICookieSerializeOptions = {}
): string {
  const { ENVIRONMENT } = getRuntimeConfig()

  const {
    expires,
    sameSite = "lax",
    secure = ENVIRONMENT !== Environment.LOCAL,
    path = "/",
    ...rest
  } = options

  const opts: CookieSerializeOptions = {
    sameSite,
    secure,
    path,
    ...rest,
  }

  if (expires !== undefined) {
    opts.expires = expires < 0 ? new Date(0) : new Date(Date.now() + expires)
  }

  const serialized = cookie.serialize(key, value, opts)

  setter(serialized)

  return serialized
}

/**
 * in order to delete a cookie its expiration date must be set to past
 */
function remove(
  setter: ICookieSetter,
  key: string,
  value: string,
  getRuntimeConfig: () => IPublicRuntimeConfig
): void {
  create(setter, key, value, getRuntimeConfig, { expires: -1 })
}

function update(
  currentCookie: string | undefined,
  setter: ICookieSetter,
  key: string,
  value: string,
  getRuntimeConfig: () => IPublicRuntimeConfig
): void {
  if (typeof currentCookie === "undefined") return

  if (has(currentCookie, key)) {
    setter(cookie.serialize(key, value))
  } else {
    create(setter, key, value, getRuntimeConfig)
  }
}

export default {
  create,
  get,
  has,
  remove,
  update,
}
