import {
  IArticle,
  INavigationMenu,
} from "../graphql/contentful-schema"
import { IAdvisor, ILink, ISlugOrArticleOrPage } from "../types"

export function isSlugOrArticleOrPage(item: any): item is ISlugOrArticleOrPage {
  return (
    item &&
    "__typename" in item &&
    (item.__typename === "Page" ||
      item.__typename === "Article" ||
      item.__typename === "Slug") &&
    item.slug
  )
}

export function isArticle(item: any): item is IArticle {
  return item && "__typename" in item && item.__typename === "Article"
}

export function isNavigationItem(item: any): item is INavigationMenu {
  return item && "navigationSubLinksCollection" in item
}

export function isLink(item: any): item is ILink {
  return item && "__typename" in item && item.__typename === "Link"
}

export function isExpert(expert: any): expert is IAdvisor {
  return expert.products !== undefined
}
