export const strings = {
  IS_REQUIRED: "Erforderliche Angabe",
  INVALID_PHONE_OR_EMAIL:
    "Ung\u00FCltige Eingabe. Bitte gib eine g\u00FCltige E-Mail-Adresse ein",
  INVALID_EMAIL: "Bitte gib eine g\u00FCltige E-Mail-Adresse ein",
  SHORT_PASSWORD: "Weniger als 6 Zeichen",
  WEAK_PASSWORD: "Nicht sehr sicher",
  NORMAL_PASSWORD: "Etwas sicher",
  STRONG_PASSWORD: "Sehr sicher",
  DEFAULT_ERROR_MESSAGE: "Etwas ist schief gelaufen",
  AUTH_ERROR_MESSAGE: "Autorisierung fehlgeschlagen",
  INVALID_EMAIL_ADDRESS_REGISTRATION:
    "Bitte verwende eine andere E-Mail-Adresse.",
  PASSWORDS_DONT_MATCH: "Passwörter sind nicht identisch.",
}
